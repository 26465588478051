<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <span>Copyright @ {{ new Date().getFullYear() }} <a href="https://www.olusumbilgiislem.com" target="_blank" rel="nofollow">Oluşum</a>, Tüm Hakları Saklıdır</span>
        
        <span class="social-links flex mt-4">
                        <a href="https://www.facebook.com/olusumbi" target="_blank" ><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon" ></feather-icon></a>
                        <a href="https://www.twitter.com/olusumbilgi" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="TwitterIcon" ></feather-icon></a>
                        <a href="https://www.instagram.com/olusumbi" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="InstagramIcon" ></feather-icon></a>
        </span>
        
        <span class="md:flex hidden items-center">
            <span>En <strong>iyisiyle</strong> Çalıştığınızdan Emin Olmalısınız</span>
            <feather-icon icon="HeartIcon" svgClasses="stroke-current text-danger w-6 h-6" class="ml-2" />
        </span>
        
    </footer>
</template>

<script>

export default {
    name: "the-footer",
    props: {
        classes: {
            type: String,
        },
    }
}
</script>
